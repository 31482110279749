<template>
  <base-info-card :title="title" :subtitle="subtitle" space="4" color="primary">
    <v-form v-model="isValid" ref="form" @submit.prevent="handleSubmitLogin">
      <base-text-field
        autofocus
        required
        type="email"
        v-model="formData.email"
        :placeholder="$t('placeholderEmail')"
        :label="$t('email')"
        :rules="rulesEmail"
      />
      <base-password-field
        required
        v-model="formData.password"
        :label="$t('password')"
        :placeholder="$t('placeholderPassword')"
        :rules="rulesGenerics"
      />

      <section class="d-flex justify-end my-4">
        <ForgotYourPassword />
      </section>

      <footer
        class="d-flex flex-column-reverse flex-md-row justify-space-between"
      >
        <base-btn
          :color="!theme.isDark ? 'primary' : 'white'"
          :to="getLinkToRegister"
          :disabled="loading"
        >
          {{ $t("iNotHaveAccount") }}
        </base-btn>
        <base-btn
          :color="!theme.isDark ? 'secondary' : 'white'"
          type="submit"
          class="my-2 my-md-0"
          :loading="loading"
          :disabled="!isValid || loading"
        >
          {{ $t("login") }}
        </base-btn>
      </footer>
    </v-form>
  </base-info-card>
</template>

<script>
import { i18n } from "@/plugins/i18n";
import { authRoutesName, menuRoutesName } from "@/router/names";
import { authCtrl } from "@/controllers";
import {
  getErrorPayloadForSnackbar,
  rulesEmail,
  rulesGenerics,
} from "@/helpers";

export default {
  name: "LoginForm",
  components: {
    ForgotYourPassword: () =>
      import("@/components/auth/ForgotYourPassword.vue"),
  },
  inject: ["theme"],
  data: () => ({
    loading: false,
    formData: { email: "", password: "" },
    isValid: false,
    rulesEmail,
    rulesGenerics,
  }),
  computed: {
    getParamsFromRoute() {
      return this.$route.params.fromRoute;
    },
    getLinkToRegister() {
      const routeName = { name: authRoutesName.REGISTER.name };
      return this.getParamsFromRoute
        ? { ...routeName, params: this.getParamsFromRoute }
        : routeName;
    },
  },
  methods: {
    async handleSubmitLogin() {
      this.loading = true;
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        this.loading = false;
        return;
      }
      await authCtrl
        .handleLogin({ ...this.formData })
        .then((res) => {
          const fromRoute = this.getParamsFromRoute;
          res && this.$router.push(fromRoute || menuRoutesName.MENU);
        })
        .catch((error) => {
          console.log(error);
          const errorPayload = getErrorPayloadForSnackbar(error);
          this.$store.commit("user/OPEN_SNACKBAR", errorPayload);
        })
        .finally(() => (this.loading = false));
    },
  },
  props: {
    subtitle: String,
    title: {
      type: String,
      default: i18n.t("login"),
    },
  },
};
</script>

<style lang="sass">
span
  cursor: pointer
</style>
